<template>
    <div>
        <div class="l-inline l-center-v l-gap-2 filter-actions">
            <i18n path="showOf">
                <b>{{ filteredTrackers.length }}</b>
                <b>{{ trackers.length }}</b>
            </i18n>

            <a v-if="isClearFilterVisible" @click="$emit('clearFilter')">
                {{ $t('clearFilter') }}
            </a>

            <IconButton :title="$t('tooltipDownload')" @click="handleDownload">
                <FileDownloadIcon width="18" height="18" />
            </IconButton>
        </div>

        <table
            v-infinite-scroll="loadMoreTrackers"
            infinite-scroll-distance="25"
        >
            <thead>
                <VDraggable
                    v-model="dynamicColumns"
                    tag="tr"
                    draggable=".dynamic"
                >
                    <template #header>
                        <th class="checkbox-cell">
                            <label>
                                <input type="checkbox" @change="selectAll" />
                            </label>
                        </th>

                        <th>{{ $t('columns.icon') }}</th>

                        <th>
                            {{ $t('columns.name') }}

                            <IconButton @click="handleSort('name')">
                                <SortArrowIcon
                                    :direction="
                                        sortBy === 'name' ? sortDirection : 0
                                    "
                                    width="10"
                                    height="10"
                                />
                            </IconButton>
                        </th>

                        <th>
                            {{ $t('columns.type') }}

                            <IconButton @click="handleSort('type')">
                                <SortArrowIcon
                                    :direction="
                                        sortBy === 'type' ? sortDirection : 0
                                    "
                                    width="10"
                                    height="10"
                                />
                            </IconButton>
                        </th>
                    </template>

                    <th
                        v-for="column in dynamicColumns"
                        :key="column"
                        class="dynamic"
                    >
                        {{ getDynamicColumnTitle(column) }}

                        <IconButton
                            v-if="!nonSortableColumns.includes(column)"
                            @click="handleSort(column)"
                        >
                            <SortArrowIcon
                                :direction="
                                    sortBy === column ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>

                        <IconButton @click="handleColumnRemove(column)">
                            <RemoveIcon width="10" height="10" />
                        </IconButton>
                    </th>

                    <template #footer>
                        <th
                            v-if="dynamicColumnOptionsRemaining.length"
                            class="action"
                        >
                            <MeasurementSelect
                                :options="dynamicColumnOptionsRemaining"
                                :taggable="isStaff"
                                :placeholder="$t('addColumnPlaceholder')"
                                group-select
                                reset-after
                                @input="handleColumnAdd"
                            />
                        </th>
                    </template>
                </VDraggable>
            </thead>

            <tr
                v-for="tracker in trackersPortion"
                :key="tracker.id"
                @click="
                    $emit('click', tracker.asset)
                    selected = [tracker.asset]
                "
            >
                <td class="checkbox-cell">
                    <label @click.stop>
                        <input
                            v-model="selected"
                            type="checkbox"
                            :value="tracker.asset"
                            @click.stop
                        />
                    </label>
                </td>

                <td>
                    <AssetAvatar :tracker="tracker" :size="32" linkable />
                </td>

                <td>
                    {{ tracker.asset_details.name }}
                </td>

                <td>
                    {{ getTrackerTypeTranslated(tracker) }}
                </td>

                <td v-for="column in dynamicColumns" :key="column">
                    <template v-if="column === 'current_locations'">
                        {{ trackerCurrentLocations[tracker.id] }}
                    </template>

                    <template v-else-if="column === 'last_contact'">
                        <TimeAgo
                            v-if="tracker.last_message_timestamp"
                            :from-datetime="tracker.last_message_timestamp"
                        />
                    </template>

                    <template v-else-if="column === 'last_gps_measurement'">
                        <TimeAgo
                            v-if="
                                tracker.last_gps_measurement &&
                                    tracker.last_gps_measurement.timestamp
                            "
                            :from-datetime="
                                tracker.last_gps_measurement.timestamp
                            "
                        />
                    </template>

                    <span
                        v-else-if="column === 'maintenance'"
                        class="list-item__tag"
                        :class="
                            tracker.asset_details.maintenance_due
                                ? 'red'
                                : 'green'
                        "
                    >
                        {{
                            tracker.asset_details.maintenance_due
                                ? $t('maintenanceStateDue')
                                : $t('maintenanceStateOk')
                        }}
                    </span>

                    <span v-else :title="getDynamicColumnDate(column, tracker)">
                        {{ getDynamicColumnValue(column, tracker) }}
                    </span>
                </td>

                <td v-if="dynamicColumnOptions.length" />
            </tr>
        </table>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { get } from 'lodash'
import { evaluate } from 'mathjs'
import moment from 'moment-timezone'
import infiniteScroll from 'vue-infinite-scroll'
import VDraggable from 'vuedraggable'

import { domHelper, formatHelper, measurementHelper } from '@/utils'
import AssetAvatar from '@/components/AssetAvatar'
import FileDownloadIcon from '@/components/icons/FileDownloadIcon'
import IconButton from '@/components/IconButton'
import MeasurementSelect from '@/components/redesigned/MeasurementSelect'
import RemoveIcon from '@/components/icons/RemoveIcon'
import SortArrowIcon from '@/components/icons/SortArrowIcon'
import TimeAgo from '@/components/TimeAgo'

const defaultScrollLimit = 15
const defaultSortColumn = 'name'
const defaultSortDirection = 1
const wildcardPrefix = '$.'

export default {
    name: 'DashboardAssetsTableView',
    components: {
        AssetAvatar,
        FileDownloadIcon,
        IconButton,
        MeasurementSelect,
        RemoveIcon,
        SortArrowIcon,
        TimeAgo,
        VDraggable,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        filterColor: {
            type: Array,
            default: () => [],
        },
        filterLocation: {
            type: Array,
            default: () => [],
        },
        filterMeasurement: {
            type: Array,
            default: () => [],
        },
        filterSearch: {
            type: String,
            default: '',
        },
        filterType: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dynamicColumnOptions: [
                ...measurementHelper.measurements,
                ...measurementHelper.measurementsAnalogChannels,
                ...measurementHelper.measurementsState,
                ...measurementHelper.measurementsTemperature,
                ...measurementHelper.measurementsVoltage,
                'current_locations',
                'dtc_codes',
                'identifier',
                'last_contact',
                'last_gps_measurement',
                'location',
                'model',
            ],
            dynamicColumns: [],
            nonSortableColumns: ['current_locations'],
            selected: [],
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            sortBy: defaultSortColumn,
            sortDirection: defaultSortDirection,
        }
    },
    computed: {
        ...mapState('dashboard', ['customColumns']),
        ...mapState('tracker', ['assetTypes', 'trackers']),
        ...mapGetters('auth', [
            'hasMaintenanceAccess',
            'isStaff',
            'isSuperuser',
        ]),
        ...mapGetters('location', ['locationNames']),
        ...mapGetters('tracker', [
            'assetMeasurementLabels',
            'assetTypesById',
            'customerFieldLabels',
        ]),
        dynamicColumnOptionsRemaining() {
            return this.dynamicColumnOptions.filter(
                column => !this.dynamicColumns.includes(column)
            )
        },
        filteredTrackers() {
            let result = this.trackers

            if (this.filterSearch) {
                result = result.filter(
                    tracker =>
                        tracker.asset_details.name
                            .toLowerCase()
                            .includes(this.filterSearch.toLowerCase()) ||
                        tracker.deveui
                            .toLowerCase()
                            .includes(this.filterSearch.toLowerCase()) ||
                        tracker.asset_details.identification
                            ?.toLowerCase()
                            .includes(this.filterSearch.toLowerCase())
                )
            }

            if (this.filterColor.length) {
                result = result.filter(tracker =>
                    this.filterColor.includes(tracker.asset_details.color)
                )
            }

            if (this.filterLocation.length) {
                result = result.filter(tracker =>
                    this.filterLocation.some(
                        location => location.id === tracker.location
                    )
                )
            }

            if (this.filterMeasurement.length) {
                const filters = this.filterMeasurement.map(item => {
                    let [measurement, value, subvalue] = item.split(/[<=>]/)
                    const operator = item.slice(measurement.length)[0]
                    if (subvalue !== undefined) {
                        measurement = value
                        value = subvalue
                    }
                    return [
                        measurement,
                        operator,
                        measurement === 'offline' || measurement === 'online'
                            ? new Date(
                                  new Date().getTime() - value * 1000
                              ).toJSON()
                            : measurement === 'running_time'
                            ? value * 3600
                            : value === 'true'
                            ? true
                            : value === 'false'
                            ? false
                            : value,
                    ]
                })
                result = result.filter(tracker =>
                    filters.every(([measurement, operator, value]) => {
                        if (
                            measurement === 'geofence' &&
                            !tracker.asset_details.static
                        ) {
                            const isCurrent = tracker.asset_details.current_locations?.includes(
                                +value
                            )
                            return operator === '=' ? isCurrent : !isCurrent
                        }
                        switch (measurement) {
                            case 'maintenance':
                                return tracker.asset_details.maintenance_due
                            case 'offline':
                                return tracker.last_message_timestamp < value
                            case 'online':
                                return tracker.last_message_timestamp > value
                        }
                        const trackerValue =
                            tracker.asset_details.sensor_data[measurement]
                                ?.value
                        return trackerValue === undefined
                            ? false
                            : operator === '<'
                            ? trackerValue < value
                            : operator === '>'
                            ? trackerValue > value
                            : trackerValue === value
                    })
                )
            }

            if (this.filterType.length) {
                result = result.filter(tracker =>
                    this.filterType.some(
                        type => type.id === tracker.asset_details.asset_type
                    )
                )
            }

            if (this.sortBy) {
                const propertyGetterMap = {
                    assetMeasurement: tracker =>
                        this.getAssetMeasurementValue(
                            tracker,
                            this.sortBy.split(':').pop()
                        ),
                    customerField: tracker =>
                        this.getCustomerFieldValue(
                            tracker,
                            this.sortBy.split(':').pop()
                        ),
                    deveui: tracker => tracker[this.sortBy],
                    identifier: tracker => tracker[this.sortBy],
                    last_contact: tracker => tracker.last_message_timestamp,
                    last_gps_measurement: tracker =>
                        tracker.last_gps_measurement?.timestamp,
                    location: tracker => tracker.location_details?.name,
                    maintenance: tracker =>
                        tracker.asset_details.maintenance_due,
                    measurement: tracker =>
                        tracker.asset_details.sensor_data[this.sortBy]?.value,
                    model: tracker => tracker.model,
                    name: tracker => tracker.asset_details.name,
                    rssi: tracker =>
                        tracker.last_measurement?.network_data?.[this.sortBy],
                    snr: tracker =>
                        tracker.last_measurement?.network_data?.[this.sortBy],
                    type: tracker => this.getTrackerTypeTranslated(tracker),
                    wildcard: tracker => {
                        const value = get(tracker, this.sortBy.slice(2))
                        return value && !isNaN(Number(value))
                            ? Number(value)
                            : value
                    },
                }

                const propertyGetter = this.sortBy.startsWith(wildcardPrefix)
                    ? propertyGetterMap.wildcard
                    : this.sortBy in this.assetMeasurementLabels
                    ? propertyGetterMap.assetMeasurement
                    : this.sortBy in this.customerFieldLabels
                    ? propertyGetterMap.customerField
                    : propertyGetterMap[this.sortBy] ||
                      propertyGetterMap['measurement']

                result = [...result].sort((a, b) => {
                    const aValue = propertyGetter(a)
                    const bValue = propertyGetter(b)

                    if (aValue === bValue) {
                        return 0
                    } else if (aValue == null) {
                        return 1
                    } else if (bValue == null) {
                        return -1
                    } else if (typeof aValue === 'string') {
                        return aValue.localeCompare(bValue) * this.sortDirection
                    }

                    return aValue > bValue
                        ? this.sortDirection
                        : -this.sortDirection
                })
            }

            return result
        },
        trackersPortion() {
            return this.filteredTrackers.length === this.trackers.length
                ? this.filteredTrackers.slice(0, this.scrollLimit)
                : this.filteredTrackers
        },
        trackerCurrentLocations() {
            return this.trackersPortion.reduce((acc, cur) => {
                if (cur.asset_details.current_locations) {
                    acc[cur.id] = cur.asset_details.current_locations
                        .map(locationId => this.locationNames[locationId])
                        .join(', ')
                }
                return acc
            }, {})
        },
        isClearFilterVisible() {
            return (
                this.filterColor.length ||
                this.filterLocation.length ||
                this.filterMeasurement.length ||
                this.filterSearch ||
                this.filterType.length
            )
        },
    },
    watch: {
        $route() {
            this.setDynamicColumnsFromRouteQuery()
        },
        dynamicColumns(value) {
            this.updateRoute(value)
        },
        selected(value) {
            this.$emit('update:selected', value)
        },
    },
    mounted() {
        this.dynamicColumnOptions.push(
            ...Object.keys(this.assetMeasurementLabels),
            ...Object.keys(this.customerFieldLabels)
        )

        if (this.isStaff) {
            this.dynamicColumnOptions.push(
                ...measurementHelper.measurementsStaffOnly,
                'deveui'
            )
        }

        if (this.isSuperuser) {
            this.dynamicColumnOptions.push('rssi', 'snr')
        }

        if (this.hasMaintenanceAccess) {
            this.dynamicColumnOptions.push('maintenance')
        }

        if (this.$route.query.column) {
            this.setDynamicColumnsFromRouteQuery()
        } else if (this.customColumns) {
            this.updateRoute(this.customColumns)
        } else {
            this.dynamicColumns = [
                'identifier',
                'location',
                'current_locations',
                'last_contact',
            ]
        }
    },
    methods: {
        ...mapMutations('dashboard', ['setCustomColumns']),
        getAssetMeasurementValue(tracker, measurement, withUnit) {
            const props = this.assetTypesById[tracker.asset_details.asset_type]
                ?.measurements?.items?.[measurement]
            const sensorData = tracker.asset_details.sensor_data
            if (!props?.measurements.every(key => sensorData[key])) {
                return
            }
            const value = props.props?.converter
                ? evaluate(
                      props.props.converter,
                      props.measurements.reduce(
                          (acc, key) => ({
                              ...acc,
                              [key]: sensorData[key].value,
                          }),
                          {}
                      )
                  )
                : sensorData[props.measurements[0]].value
            return typeof value === 'boolean' && withUnit
                ? value
                    ? this.$t('shared.yes')
                    : this.$t('shared.no')
                : value !== undefined && withUnit && props.props.unit
                ? `${value} ${props.props.unit}`
                : value
        },
        getCustomerFieldValue(tracker, field) {
            const schema = this.assetTypesById[tracker.asset_details.asset_type]
                ?.additional_data_schema.properties
            const value =
                tracker.asset_details.additional_data?.[field] ??
                schema?.[field]?.default
            return schema && value !== undefined
                ? schema[field].format === 'boolean'
                    ? value
                        ? this.$t('shared.yes')
                        : this.$t('shared.no')
                    : schema[field].format === 'date'
                    ? moment(value).format('DD.MM.YYYY')
                    : value
                : undefined
        },
        getDynamicColumnTitle(column) {
            return (
                this.assetMeasurementLabels[column] ??
                this.customerFieldLabels[column] ??
                (this.$te(`columns.${column}`)
                    ? this.$t(`columns.${column}`)
                    : this.$root.$te(`shared.measurements.${column}`)
                    ? this.$t(`shared.measurements.${column}`)
                    : column)
            )
        },
        getDynamicColumnValue(column, tracker) {
            if (column in this.assetMeasurementLabels) {
                const [, assetType, measurement] = column.split(':')
                return tracker.asset_details.asset_type === +assetType
                    ? this.getAssetMeasurementValue(tracker, measurement, true)
                    : undefined
            }

            if (column in this.customerFieldLabels) {
                const [, assetType, field] = column.split(':')
                return tracker.asset_details.asset_type === +assetType
                    ? this.getCustomerFieldValue(tracker, field)
                    : undefined
            }

            switch (column) {
                case 'deveui':
                    return tracker.deveui
                case 'identifier':
                    return tracker.identifier
                case 'location':
                    return tracker.location_details?.name
                case 'model':
                    return tracker.model
                case 'rssi':
                    return tracker.last_measurement?.network_data?.rssi
                        ? `${tracker.last_measurement.network_data.rssi} dBm`
                        : undefined
                case 'snr':
                    return tracker.last_measurement?.network_data?.snr
            }

            if (column.startsWith(wildcardPrefix)) {
                return get(tracker, column.slice(2))
            }

            let value = tracker.asset_details.sensor_data[column]?.value

            if (typeof value === 'boolean') {
                return this.$t(value ? 'on' : 'off')
            } else if (typeof value !== 'number') {
                return value
            }

            switch (column) {
                case 'running_time':
                    return formatHelper.hoursAndMinutesDuration(value)
                default:
                    value = (
                        measurementHelper.converters[column] ||
                        measurementHelper.converters.default
                    )(value)
                    break
            }

            if (measurementHelper.units[column]) {
                value = `${value} ${measurementHelper.units[column]}`
            }

            return value
        },
        getDynamicColumnDate(column, tracker) {
            const lastUpdate =
                tracker.asset_details.sensor_data[column]?.last_update

            return lastUpdate
                ? moment(lastUpdate).format('DD.MM.YYYY HH:mm')
                : null
        },
        getTrackerTypeTranslated(tracker) {
            const translationKey = `shared.types.${tracker.asset_details.asset_type_identifier}`

            return this.$root.$te(translationKey)
                ? this.$t(translationKey)
                : this.assetTypes.find(
                      type => type.id === tracker.asset_details.asset_type
                  )?.name
        },
        handleColumnAdd(column) {
            if (!column) {
                return
            }

            const columns = [].concat(column)
            columns.forEach(item => {
                if (!this.dynamicColumns.includes(item)) {
                    this.dynamicColumns.push(item)
                }
            })
        },
        handleColumnRemove(column) {
            if (this.sortBy === column) {
                this.sortBy = defaultSortColumn
                this.sortDirection = defaultSortDirection
            }

            this.dynamicColumns = this.dynamicColumns.filter(
                item => item !== column
            )
        },
        handleDownload() {
            const rows = [
                [
                    this.$t('columns.name'),
                    this.$t('columns.type'),
                    ...this.dynamicColumns.map(this.getDynamicColumnTitle),
                ],
                ...this.filteredTrackers.map(tracker => [
                    tracker.asset_details.name,
                    this.getTrackerTypeTranslated(tracker),
                    ...this.dynamicColumns.map(column =>
                        this.getDynamicColumnValue(column, tracker)
                    ),
                ]),
            ]

            const dataUrl = `data:text/csv;charset=utf-8,${rows
                .map(row => row.join(','))
                .join('\r\n')}`

            const filename = `${this.$t('shared.assets')}-${moment().format(
                'YYYY-MM-DD-HHmmss'
            )}.csv`

            domHelper.downloadDataUrl(dataUrl, filename)
        },
        handleSort(column) {
            if (this.sortBy !== column || this.sortDirection !== 1) {
                this.sortBy = column
                this.sortDirection = 1
            } else if (this.sortDirection === 1) {
                this.sortDirection = -1
            }
        },
        loadMoreTrackers() {
            if (this.scrollLimit < this.trackers.length) {
                this.scrollLimit += this.scrollStep
            }
        },
        selectAll(event) {
            this.selected = event.target.checked
                ? this.filteredTrackers.map(tracker => tracker.asset)
                : []
        },
        setDynamicColumnsFromRouteQuery() {
            if (!this.$route.query.column?.length) {
                this.setCustomColumns(null)
                return
            }

            this.dynamicColumns =
                typeof this.$route.query.column === 'string'
                    ? [this.$route.query.column]
                    : this.$route.query.column.filter(
                          (column, i, columns) => columns.indexOf(column) === i
                      )

            this.setCustomColumns(this.dynamicColumns)
        },
        updateRoute(columns) {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    column: columns,
                },
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "columns": {
            "current_locations": "Current locations",
            "deveui": "DevEUI",
            "icon": "Farbe",
            "identifier": "Identifier",
            "last_contact": "Last contact",
            "last_gps_measurement": "Last GPS measurement",
            "location": "Location",
            "maintenance": "Maintenance state",
            "model": "Model",
            "name": "Name",
            "rssi": "RSSI",
            "snr": "SNR",
            "type": "Type"
        },
        "addColumnPlaceholder": "Additional column",
        "clearFilter": "Clear filter",
        "maintenanceStateDue": "Due",
        "maintenanceStateOk": "Ok",
        "off": "Off",
        "on": "On",
        "showOf": "Show {0} of {1}",
        "tooltipDownload": "CSV Export"
    },
    "de": {
        "columns": {
            "current_locations": "Aktuelle Standorte",
            "deveui": "DevEUI",
            "icon": "Farbe",
            "identifier": "Identifikation",
            "last_contact": "Letzter Kontakt",
            "last_gps_measurement": "Letzte GPS Messung",
            "location": "Standort",
            "maintenance": "Wartungszustand",
            "model": "Modell",
            "name": "Name",
            "rssi": "RSSI",
            "snr": "SNR",
            "type": "Typ"
        },
        "addColumnPlaceholder": "Zusätzliche Spalte",
        "clearFilter": "Filter zurücksetzen",
        "maintenanceStateDue": "Fällig",
        "maintenanceStateOk": "Ok",
        "off": "Aus",
        "on": "Ein",
        "showOf": "Zeige {0} von {1}",
        "tooltipDownload": "CSV Export"
    },
    "fr": {
        "columns": {
            "current_locations": "Emplacements actuels",
            "deveui": "DevEUI",
            "icon": "Couleur",
            "identifier": "Idantifiant",
            "last_contact": "Dernier contact",
            "last_gps_measurement": "Dernière mesure GPS",
            "location": "Emplacement",
            "maintenance": "Status de maintenance",
            "model": "Modèle",
            "name": "Nom",
            "rssi": "RSSI",
            "snr": "SNR",
            "type": "Type"
        },
        "addColumnPlaceholder": "Colomne additionnelle",
        "clearFilter": "Supprimer le filtre",
        "maintenanceStateDue": "Dû",
        "maintenanceStateOk": "Ok",
        "off": "Off",
        "on": "On",
        "showOf": "Afficher {0} de {1}",
        "tooltipDownload": "Exporter CSV"
    },
    "it": {
        "columns": {
            "current_locations": "Luoghi attuali",
            "deveui": "DevEUI",
            "icon": "Farbe",
            "identifier": "Identifier",
            "last_contact": "Ultimo Contatto",
            "last_gps_measurement": "Ultimo Misurazione del GPS",
            "location": "Locazione",
            "maintenance": "Stato di manutenzione",
            "model": "Modello",
            "name": "Name",
            "rssi": "RSSI",
            "snr": "SNR",
            "type": "Type"
        },
        "addColumnPlaceholder": "Colonna aggiuntiva",
        "clearFilter": "Annullare il filtro",
        "maintenanceStateDue": "Dovuto",
        "maintenanceStateOk": "Ok",
        "off": "Off",
        "on": "On",
        "showOf": "Listo {0} di {1}",
        "tooltipDownload": "CSV Export"
    }
}
</i18n>

<style lang="scss" scoped>
.filter-actions {
    position: sticky;
    top: 0;
    left: 0;
    padding: 1rem 2rem;
    height: 52px;
    background-color: #fff;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    z-index: 1;

    a {
        font-size: 13px;
        color: #000;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    button {
        position: absolute;
        right: 2rem;
        color: #000;
    }
}

.checkbox-cell {
    height: 100%;
    padding: 0;

    input,
    label {
        cursor: pointer;
    }

    label {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0.5rem 1rem 0.5rem 2rem;
    }
}

table {
    display: block;
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    thead {
        position: sticky;
        top: 52px;
        background-color: #fff;
        z-index: 1;

        .icon-button {
            margin-left: 5px;
        }
    }

    tr {
        height: 100%;

        &:not(:first-child) {
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter;
            }

            & + tr {
                td {
                    border-top: $style-border;
                }
            }
        }

        th {
            padding: 1rem;
            border-top: $style-border;
            border-bottom: $style-border;
            text-align: left;

            &.action {
                padding-top: 0;
                padding-bottom: 0;
                font-weight: normal;

                & > * {
                    width: 240px;
                }
            }

            &.dynamic {
                cursor: grab;
                transition: box-shadow 0.1s;

                &:active {
                    cursor: grabbing;
                }

                &:hover {
                    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
                }

                &.sortable-chosen,
                &.sortable-ghost {
                    background-color: #fff;
                    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
                }
            }
        }

        td {
            padding: 0.5rem 1rem;
        }

        th,
        td {
            white-space: nowrap;

            &:not(:last-child) {
                width: 1%;
            }
        }
    }
}
</style>
